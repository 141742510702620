const it = {
    add: 'Aggiungi',
    continue: 'Continua',
    start: 'Inizia',
    sign_in: 'Accedi',
    sign_up: 'Registrati',
    create_account: 'Crea account',
    exit: 'Esci',
    choose: 'Scegli',
    of: 'di',
    email: 'Email',
    password: 'Password',
    new_password: 'Nuova Password',
    current_password: 'Password Attuale',
    current_password_error: 'LA password attuale non è corretta',
    password_changed_success: 'Password cambiata correttamente',
    password_changed_error: 'Errore - La password non è stata cambiata correttamente',
    repeat_password: 'Ripeti password',
    repeat_new_password: 'Ripeti nuova password',
    edit_password: 'Modifica password',
    accept: 'Accetto',
    name: 'Nome',
    surname: 'Cognome',
    all: 'Tutti',
    city: 'Città',
    nation: 'Nazione',
    or_else: 'oppure',
    save: 'Salva',
    jump: 'Salta',
    hello: 'Ciao',
    verify_email_message:
        'ti consigliamo di verificare il tuo indirizzo email per continuare ad utilizzare la piattaforma',
    verify_email: 'Invia di nuovo',
    view_type_company: 'Passa alla modalità azienda',
    view_type_user: 'Passa alla modalità talento',
    admin_dashboard: 'Dashboard admin',
    italian: 'Italiano',
    english: 'English',
    account: 'Account',
    logout: 'Logout',
    edit: 'Modifica',
    preview: 'Anteprima',
    description: 'Descrizione',
    title: 'Titolo',
    complete: 'Completo',
    settings: 'Impostazioni',
    and: 'e',
    search_skills: 'Cerca competenze',
    step: 'Step',
    skills: 'Competenze',
    yes: 'Si',
    no: 'No',
    experience_year: 'Anni di esperienza',
    back: 'Indietro',
    next: 'Avanti',
    publish: 'Pubblica',
    add_skills: 'Aggiungi competenze',
    save_skills: 'Salva competenze',
    dashboard_menu: 'Dashboard',
    search_menu: 'Cerca aziende e opportunità',
    jobs_menu: 'Offerte di lavoro',
    match_menu: 'Match',
    learning_menu: 'Formazione',
    empty_params: 'Per favore inserisci tutti i campi',
    messages: 'Messaggi',
    notifications: 'Notifiche',
    recent_searches: 'Le mie ricerce',
    seconds: 'secondi',
    save_photo: 'Salva foto',
    cancel: 'Annulla',
    typology: 'Tipologia',
    role: 'Ruolo',
    agency: 'Agenzia',
    search: 'Cerca',
    refused: 'Non approvato',
    confirm_action: 'Sei sicuro di voler effettuare questa azione?',
    coverimage: 'immagine di copertina',
    previewimage: 'immagine di preview',
    photo: 'foto profilo',
    subscriptions: 'Abbonamenti',
    advanced_search: 'Ricerca avanzata',
    phone: 'Telefono',
    uploaded: 'In elaborazione',
    completed: 'Processati',
    search_history: 'Storico ricerche',
    active: 'attivo',
    closed: 'chiuso',
    check_email: 'Controlla la tua email',
    check_email_text:
        'Per continuare ad utilizzare la piattaforma Wh verifica il tuo indirizzo email!',
    check_email_subtext: 'Non hai ricevuto la mail? Richiedila di nuovo.',
    selected: 'selezionato',
    drafted: 'scartato',
    upload_status: {
        open: 'Aperto',
        completed: 'Completato',
        created: 'Da caricare',
        uploaded: 'Caricato',
        processing: 'In elaborazione',
    },
    years: 'anni',
    check_birthdate: 'Controlla la tua data di nascita',
    signin_disclaimer:
        'Selezionando Accedi, confermi di accettare le nostre Condizioni. Scopri come elaboriamo i tuoi dati nella nostra Informativa sulla Privacy e Informativa sui Cookie',
    show_all: 'Visualizza tutti',
    search_by_name: 'Cerca per nome',
    filter: 'Filtra per',
    expired: 'Scaduti',
    expire: 'Scaduto',
    close: 'Chiudi',
    description_too_long: 'Controlla la descrizione',
    start_title_1: 'Intelligenza Artificiale per il',
    start_title_2: 'recruiting strategico e',
    start_title_3: "l'HR data intelligence.",
    start_subtitle_1: 'Analizza e arricchisci i dati dei CV.',
    start_subtitle_2: 'Scopri il potenziale dei candidati. Configura il miglior team. ',
    download_app: 'Scarica l’app',
    trusted_by: 'supportata da',
    selected_in: 'selezionata in',
    filter_by: 'Filtra per',
    signup_on_wh: 'Registrati su WH',
    offert_detail: "Dettagli dell'offerta",
    candidature_send: 'Invia candidatura',
    candidate_thank_you: 'Grazie per aver inviato la tua candidatura.',
    candidate_thank_you_message:
        'Valuteremo il tuo profilo e se in linea con la ricerca verrai ricontattato.',
    candidate_thank_you_message_2:
        'Nell’attesa puoi registrarti su Wh per avere accesso ad altre opportunità!',
    look_for_talents: 'Cerca candidati',
    create_team_list: 'Crea team list',
    saved_profiles: 'Profili salvati',
    my_team_list: 'Le mie team list',
    upload_cv: 'Upload CV',
    dashboard_hiring: 'Dashboard Hiring',
    dashboard_hiring_staffing: 'Dashboard HR / Hiring & Staffing',
    post_job_offers: 'Pubblica offerte di lavoro',
    my_job_offers: 'Le mie offerte di lavoro',
    dashboard_hr: 'Dashboard HR',
    management: 'Management',
    check_compatibility: 'Verifica compatibilità',
    performance_review: 'Performance review',
    my_organization_chart: 'Il mio organigramma',
    my_employees: 'I miei Employees',
    interview_diary: 'Agenda colloqui',
    remote_work: 'Lavoro in remoto',
    remote_work_mobile: 'Remoto',
    work_on_site: 'Lavoro in sede',
    work_on_site_mobile: 'In sede',
    hybrid_work: 'Hybrid',
    enter_your_workplace: 'Inserisci la città',
    duration: 'Durata',
    activate: 'Attiva',
    openhr_requests_list: 'Richieste di collegamento',
    complete_profile: 'Completa profilo',
    complete_profile_message:
        'Completa il tuo profilo su Wh per autocandidarti alle offerte di',
    complete_profile_message_2: 'e per conoscere altre opportunità!',
    no_job_announcement_message: 'ci dispiace ma non ci sono annunci per te',
    copy_url: 'Copia url',
    url_copied: 'Url copiata',
    home: 'Home',
    open_hr: 'Open HR',
    open_education: "Vai all'area Education",
    where: 'Dove',
    my_cv: 'I miei CV',
    involves: 'Implica',
    relates: 'Correla',
    file_name: 'Nome file',
    qualification: 'Qualifica',
    corresponding: 'corrispondenza',
    junior: 'Junior',
    middle: 'Middle',
    senior: 'Senior',
    two: 'Entry level',
    three_five: '3-5 anni',
    five_nine: '5-9 anni',
    other_ten: '10+ anni',
    small: 'Piccola',
    medium: 'Media',
    big: 'Grande',
    age: 'Età',
    seniority: 'Seniority',
    privacy_cookie: 'Privacy e Cookie Policy',
    terms_and_conditions: 'Termini e Condizioni',
    terms_of_service: 'Termini di Servizio',
    service_center: 'Centro assistenza',
    invalid_vat:
        'Formato partita iva non valido. Inserire la partita iva preceduta dal codice nazione (es. IT12345678901)',
    forgot_password: 'Ho dimenticato la password',
    reset_password: 'Reset Password',
    reset_password_text:
        'Inserisci la tua email e clicca per ricevere un email con le instruzioni per impostare una nuova password',
    send_email: 'Invia email',
    reset_password_email_sent:
        'Email per il reset della password inviata correttamente. Clicca sul link che ti abbiamo inviato e inserisci una nuova password',
    reset_password_email_sent_error: 'Errore durante il reset della password. Riprovare',
    create_new_password: 'Crea una nuova password per continuare su Wh!',
    click_to_retry: 'Clicca qui per ricominciare.',
    password_reset_ko: 'Errore. La password non è stata impostata correttamente.',
    password_reset_ok: 'La password è stata impostata correttamente.',
    go_to_login: 'Clicca qui per accedere a WH!',
    companyName: 'Nome azienda',
    message: 'Messaggio',
    send: 'Invia',
    where_i_am: 'Dove mi trovo',
    login_error: 'Errore login. Credenziali non corrette',
    last_update: 'Ultimo aggiornamento',
    upload_date: 'Data caricamento CV',
    required_field: '* Campo obbligatorio',
    no_skills: 'Non ci sono competenze che corrispondono alla tua ricerca',
    summary_generated_by: 'Riepilogo generato da:',
    email_sent: 'Email inviata con successo',
    check_form_fields: 'Controlla i campi del form',
    email_sent_error: 'Abbiamo riscontrato un problema nell’invio della mail.',
    not_specified: 'Non specificato',
    share_tiplogy_type: 'Tipologia di condivisione',
    skip: 'salta',
    update: 'Aggiorna',
    no_keywords: 'Non ci sono keywords che corrispondono alla tua ricerca',
    actions: 'azioni',
    work_preferences: 'Preferenze di lavoro',
    cities: 'Città',
    countries: 'Nazioni',
    eu: 'Europa 🇪🇺',
    usa: 'America 🇺🇸',
    initiative_cofinanced: 'INIZIATIVA COFINANZIATA DALL’UNIONE EUROPEA',
    puglia: 'MEDIANTE IL P.O PUGLIA - FESR FSE 2014-2020',
    footer_info_1: 'Asse I - Ricerca, Sviluppo Tecnologico e Innovazione / Azione 1.5',
    footer_info_2: 'Asse III - Competitività delle piccole e medie imprese / Azione 3.8',
    footer_label_tecnonidi:
        'MISURA TECNONIDI - PROGETTO REALIZZATO DALL’IMPRESA WH TECH SRL',
    footer_address:
        'Sede operativa: Corso Vittorio Emanuele II, 30 - 70122 Bari (BA), Italia',
    skill_search_insert: 'Inserisci qui le skill che stai cercando',
    corresponding_search: 'Corrispondono alla tua ricerca',
    talents: 'talenti',
    employee: 'dipendenti',
    in_your_db: 'nel tuo database',
    analize_cv: 'Stiamo analizzando i cv...',
    cv_in_database: 'Il CV è già presente nel tuo database',
    cv_edited_by_recruiter: 'ed è stato aggiornato da un recruiter.',
    hire_candidate: 'Assumi il candidato',
    no_cities: 'Non ci sono città che corrispondono alla tua ricerca',
    sectors: 'Settori',
    special_category: 'Categorie speciali',
    special_category_label: 'Appartenente alle categorie protette (legge 68/99)',
    no_sectors: 'Non ci sono settori che corrispondono alla tua ricerca',
    suggested_cv: 'CV suggeriti',
    search_by_age: 'Cerca per età',
};
export default it;
